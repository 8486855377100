import React, { useState } from "react";
import axios from "axios";

export default function TechAiSM() {
  // State to store the selected date and API response
  const [selectedDate, setSelectedDate] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Function to call the Lambda API
  const callLambdaAPI = async (date) => {
    const apiUrl = "https://hrxbn8rtq5.execute-api.us-east-1.amazonaws.com/dev/";
    const requestData = {
      columns: "Date, Price",
      symbol: "^GSPC",
      date: date, // Use the dynamic date
    };

    try {
      const response = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data; // Return API response
    } catch (error) {
      console.error("Error calling API:", error);
      throw error;
    }
  };

  // Handle API call when the button is clicked
  const handleAPICall = async () => {
    setApiResponse(null); // Reset previous response
    setErrorMessage(null); // Reset previous error

    if (!selectedDate) {
      alert("Please select a date.");
      return;
    }

    try {
      const data = await callLambdaAPI(selectedDate);
      console.log("Fetched Data:", data);

      // Parse and set API response for display
      if (data && data.body) {
        const parsedData = JSON.parse(data.body); // Parse the "body" JSON string

        if (parsedData.length === 0) {
          // Handle empty response
          setErrorMessage("No data available for the selected date.");
        } else {
          setApiResponse(parsedData);
        }
      } else {
        setErrorMessage("Unexpected response format from the API.");
      }
    } catch (error) {
      setErrorMessage("Failed to fetch data. Please try again later.");
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial" }}>
      <h1>Fetch Price by Date</h1>
      <div>
        <label htmlFor="datePicker">Select Date: </label>
        <input
          id="datePicker"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)} // Update state with selected date
        />
      </div>
      <button onClick={handleAPICall} style={{ marginTop: "10px" }}>
        Fetch Data
      </button>
      <div style={{ marginTop: "20px" }}>
        <h2>Response:</h2>
        {errorMessage ? (
          <p style={{ color: "red" }}>{errorMessage}</p>
        ) : apiResponse ? (
          <div>
            <p>
              <strong>Date:</strong> {apiResponse[0][0]}
            </p>
            <p>
              <strong>Price:</strong> {apiResponse[0][1]}
            </p>
          </div>
        ) : (
          <p>No data fetched yet.</p>
        )}
      </div>
    </div>
  );
}
